














































import { Component, Vue, PropSync } from 'vue-property-decorator';

@Component({ name: 'NoticeOfPrivacy' })
export default class NoticeOfPrivacy extends Vue {
  @PropSync('isOpen', { type: Boolean }) synced_is_open!: boolean;

  i18n_namespace = 'components.contract-savings.before-start.notice_of_privacy';

  accept = null;

  radioChange() {
    const accept_notice_of_privacy = this.accept === 'yes' ?? false;
    this.$emit('handleNoticeOfPrivacy', accept_notice_of_privacy);
    this.synced_is_open = false;
  }
}
