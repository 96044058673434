var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"primary--text"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-img',{attrs:{"src":require('@/assets/icons/documents.svg'),"max-width":"104px"}})],1),_c('h3',{staticClass:"text-center ma-5"},[_vm._v(" "+_vm._s(_vm.insurance_view_model.translate('title'))+" ")]),_c('p',{class:{
      'text-font-secondary': true,
      'text-center': !_vm.insurance_view_model.small_screen
    }},[_vm._v(" "+_vm._s(_vm.insurance_view_model.translate('description'))+" ")]),_c('p',{class:{
      'text-font-secondary mb-0': true,
      'text-center': !_vm.insurance_view_model.small_screen
    }},[_vm._v(" "+_vm._s(_vm.insurance_view_model.translate('info'))+" ")]),_c('p',{class:{
      'text-font-secondary inactive-blue--text': true,
      'text-center': !_vm.insurance_view_model.small_screen
    }},[_vm._v(" "+_vm._s(_vm.insurance_view_model.translate('conditions'))+" ")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-checkbox',{attrs:{"name":"accept_terms_and_conditions","color":"secondary"},on:{"change":_vm.insurance_view_model.openTermsAndConditionsIfCheckboxIsActive},model:{value:(_vm.insurance_view_model.on_boarding_step.payload.accept_terms_and_conditions),callback:function ($$v) {_vm.$set(_vm.insurance_view_model.on_boarding_step.payload, "accept_terms_and_conditions", $$v)},expression:"insurance_view_model.on_boarding_step.payload.accept_terms_and_conditions"}}),_c('span',{staticClass:"text-font-secondary"},[_vm._v(" "+_vm._s(_vm.insurance_view_model.translate('read_and_accept_terms'))+" "),_c('span',{staticClass:"cursor-pointer text-decoration-underline text-font-secondary",class:{
          'font-weight-bold': _vm.insurance_view_model.small_screen,
        },on:{"click":_vm.insurance_view_model.openTermsAndConditions}},[_vm._v(" "+_vm._s(_vm.insurance_view_model.translate('terms'))+" ")])])],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-checkbox',{attrs:{"name":"accept_notice_of_privacy","color":"secondary"},on:{"change":_vm.insurance_view_model.openNoticeOfPrivacyIfCheckboxIsActive},model:{value:(_vm.insurance_view_model.on_boarding_step.payload.accept_notice_of_privacy),callback:function ($$v) {_vm.$set(_vm.insurance_view_model.on_boarding_step.payload, "accept_notice_of_privacy", $$v)},expression:"insurance_view_model.on_boarding_step.payload.accept_notice_of_privacy"}}),_c('span',{staticClass:"text-font-secondary"},[_vm._v(" "+_vm._s(_vm.insurance_view_model.translate('read_and_accept_privacy'))+" "),_c('span',{staticClass:"cursor-pointer text-decoration-underline text-font-secondary",class:{
          'font-weight-bold': _vm.insurance_view_model.small_screen,
        },on:{"click":_vm.insurance_view_model.openNoticeOfPrivacy}},[_vm._v(" "+_vm._s(_vm.insurance_view_model.translate('notice_of_privacy'))+" ")])])],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mt-10",attrs:{"color":"accent","x-large":"","rounded":"","type":"submit","width":"80%","disabled":_vm.insurance_view_model.continue_button_disabled,"name":"start"},on:{"click":_vm.nextStep}},[_vm._v(" "+_vm._s(_vm.insurance_view_model.translate('start'))+" ")])],1),_c('terms-and-conditions-allianz',{attrs:{"isOpen":_vm.insurance_view_model.open_terms_and_conditions},on:{"update:isOpen":function($event){return _vm.$set(_vm.insurance_view_model, "open_terms_and_conditions", $event)},"update:is-open":function($event){return _vm.$set(_vm.insurance_view_model, "open_terms_and_conditions", $event)},"handleTermsAndConditions":_vm.insurance_view_model.acceptTermsAndConditions,"closeModal":_vm.insurance_view_model.closeTermsAndConditions}}),_c('notice-of-privacy',{attrs:{"isOpen":_vm.insurance_view_model.open_notice_of_privacy},on:{"update:isOpen":function($event){return _vm.$set(_vm.insurance_view_model, "open_notice_of_privacy", $event)},"update:is-open":function($event){return _vm.$set(_vm.insurance_view_model, "open_notice_of_privacy", $event)},"handleNoticeOfPrivacy":_vm.insurance_view_model.acceptNoticeOfPrivacy}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }