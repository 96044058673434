

































































import { Component, Vue, PropSync } from 'vue-property-decorator';

@Component({ name: 'TermsAndConditionsAllianz' })
export default class TermsAndConditionsAllianz extends Vue {
  @PropSync('isOpen', { type: Boolean }) synced_is_open!: boolean;

  i18n_namespace = 'components.contract-savings.before-start.terms_and_conditions';

  sheet = false;

  accept_receive_mail = false;

  accept_bank_account = false;

  accept_share_info = false;

  disabled() {
    return !this.accept_receive_mail || !this.accept_bank_account || !this.accept_share_info;
  }

  acceptTermsAndConditions() {
    this.$emit('handleTermsAndConditions', true);
    this.synced_is_open = false;
  }

  closeModal() {
    this.$emit('closeModal');
  }
}
