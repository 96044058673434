import { render, staticRenderFns } from "./TermsAndConditionsAllianz.vue?vue&type=template&id=3f8cfa69&"
import script from "./TermsAndConditionsAllianz.vue?vue&type=script&lang=ts&"
export * from "./TermsAndConditionsAllianz.vue?vue&type=script&lang=ts&"
import style0 from "./TermsAndConditionsAllianz.vue?vue&type=style&index=0&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VCard,VCheckbox,VContainer,VDialog,VIcon,VSheet})
