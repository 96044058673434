import TYPES from '@/types';
import { v4 as uuid } from 'uuid';
import i18n from '@/vue-app/plugins/i18n';

// Application
import { SearchOnBoardingStepsQuery }
  from '@/modules/my-investment/on-boarding-steps/application/queries';
import { SearchInvestmentProvidersQuery }
  from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';

// Domain
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class ContractSavingsInvestmenInsuranceAllianzViewModel {
  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  private readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  readonly i18n_namespace = 'components.contract-savings.before-start.investment_insurance';

  readonly error_message = i18n.tc('components.contract-savings.error_on_save_step');

  small_screen: null | boolean = null;

  form_validity = false;

  investment_provider_name = 'allianz';

  exists_step = false;

  step_name = 'introduction';

  investment_provider_id = '';

  is_loading = true;

  on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {
      accept_notice_of_privacy: false,
      accept_terms_and_conditions: false,
      authorize_receive_notifications: false,
      authorize_sharing_personal_info: false,
      authorize_sharing_status_account: false,
      authorize_processing_of_personal_data: false,
    },
  };

  open_terms_and_conditions = false;

  open_notice_of_privacy = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get continue_button_disabled() {
    const { payload } = this.on_boarding_step;
    return !payload.accept_notice_of_privacy || !payload.accept_terms_and_conditions
      || this.is_loading;
  }

  openTermsAndConditions = () => {
    this.open_terms_and_conditions = true;
  }

  openTermsAndConditionsIfCheckboxIsActive = () => {
    if (this.on_boarding_step.payload.accept_terms_and_conditions) {
      this.open_terms_and_conditions = true;
    }
  }

  openNoticeOfPrivacy = () => {
    this.open_notice_of_privacy = true;
  }

  openNoticeOfPrivacyIfCheckboxIsActive = () => {
    if (this.on_boarding_step.payload.accept_notice_of_privacy) {
      this.open_notice_of_privacy = true;
    }
  }

  acceptTermsAndConditions = (accept: boolean) => {
    this.on_boarding_step.payload.accept_terms_and_conditions = accept;
    this.on_boarding_step.payload.authorize_receive_notifications = accept;
    this.on_boarding_step.payload.authorize_sharing_personal_info = accept;
    this.on_boarding_step.payload.authorize_sharing_status_account = accept;
  }

  acceptNoticeOfPrivacy = (accept: boolean) => {
    this.on_boarding_step.payload.accept_notice_of_privacy = accept;
    this.on_boarding_step.payload.authorize_processing_of_personal_data = accept;
  }

  get investmentProviders() {
    return this.searchInvestmentProvidersQuery.execute();
  }

  get steps() {
    return this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);
  }

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    if (on_boarding_steps.length) {
      const search_step = on_boarding_steps.find(
        (step) => step.current_step === this.step_name,
      );
      if (search_step) {
        this.exists_step = true;
        this.on_boarding_step = { ...search_step };
      }
    }
  };

  async saveStep() {
    this.is_loading = true;
    try {
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      if (this.exists_step) {
        delete this.on_boarding_step.customer_id;
        await this.updateOnBoardingStepCommand.execute(this.on_boarding_step);
      } else {
        await this.createOnBoardingStepCommand.execute(this.on_boarding_step);
        this.exists_step = true;
      }
    } catch (error) {
      this.messageNotifier.showErrorNotification(this.error_message);
      this.is_loading = true;
      return false;
    }
    this.is_loading = true;
    return true;
  }

  closeTermsAndConditions = () => {
    this.open_terms_and_conditions = false;
    if (!this.on_boarding_step.payload.authorize_receive_notifications
      || !this.on_boarding_step.payload.authorize_sharing_personal_info
      || !this.on_boarding_step.payload.authorize_sharing_status_account) {
      this.on_boarding_step.payload.accept_terms_and_conditions = false;
    }
  }

  initialize = async () => {
    const investment_providers = await this.investmentProviders;
    this.setInvestmentProviderId(investment_providers);

    const steps = await this.steps;
    this.verifyStep(steps);

    this.is_loading = false;
  }
}
